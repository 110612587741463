<template>

  <div>
    <!-- <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <!-- <AccountsFilter
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      class="mb-0"
    >
      <b-row class="py-1">
        <b-col cols="12">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <b-button
                variant="primary"
                class="btn-icon"
                :to="{name: 'accounts-create'}"
              >
                <feather-icon icon="UserPlusIcon" />
                {{ $t('Add New Account') }}
              </b-button>
            </div>
            <div>
              <VueGoodTableSwitchCompactMode />
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="table"
        >
          <VueGoodTableRemote
            ref="tableDataMember"
            :total-records="totalRecords"
            :rows="rows"
            :columns="columns"
            :is-line-number="true"
            :is-loading="$wait.is('table-loading-data') || $wait.is('loading-on-action-update-max-credit-limit')"
            :pagination-enabled="totalRecords >= 100"
            :select-enabled="true"
            @onLoadData="onLoadData"
            @selectionChanged="selectionChanged"
          >
            <div slot="my-selected-row-actions">
              <Button
                :text="$t('Update Max Credit Limit')"
                variant="primary"
                :loading="$wait.is('loading-on-action-update-max-credit-limit')"
                @click="onUpdateMaxCreditLimit"
              />
            </div>
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'status'">
                <b-badge :variant="statusColorBoolean(props.row.active)">
                  {{ statusActiveBoolean(props.row.active) }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'creditLimit'">
                <span>
                  {{ props.formattedRow[props.column.field] | formatNumberMoney }}
                </span>
              </span>
              <div
                v-else-if="props.column.field === 'username'"
                class="table__row"
              >
                <!-- <b-button
                  :class="[props.row.type === 'AGENT' ? 'link--text-latest' : 'link--text']"
                  variant="link"
                  style="user-select: text;"
                  @click="onViewDownline(props.row)"
                >
                  {{ props.formattedRow[props.column.field] }}
                </b-button> -->
                <span
                  :class="[props.row.type === 'AGENT' ? 'link--text-latest' : 'link--text']"
                  @click="onViewDownline(props.row)"
                >
                  {{ props.formattedRow[props.column.field] }}

                </span>
              </div>
              <span v-else-if="props.column.field === 'last_login'">
                {{ DatetimeFormat(props.row.last_login) }}
              </span>
              <div
                v-else-if="props.column.field === 'editSetting'"
                class="d-flex justify-content-center"
              >
                <b-button
                  variant="primary"
                  class="btn-icon rounded-circle"
                  size="sm"
                  :to="{
                    name: 'accounts-edit',
                    params: {
                      id: props.row._id
                    }
                  }"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </div>
              <div
                v-else-if="props.column.field === 'ActionCredit'"
                class="d-flex justify-content-center"
              >
                <div class="pr-1">
                  <b-button
                    variant="success"
                    class="btn-icon rounded-circle"
                    size="sm"
                    @click.prevent="onIncrementCredit(props.row)"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>

                </div>
                <div>
                  <b-button
                    variant="danger"
                    class="btn-icon rounded-circle"
                    size="sm"
                    @click.prevent="onDecrementCredit(props.row)"
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>
                </div>
              </div>

              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </VueGoodTableRemote>
        </b-col>
      </b-row>
    </b-card>

    <ModalIncrementDecrementCreditComponent
      :show.sync="modalCredit.show"
      :type="modalCredit.type"
      :username="modalCredit.username"
      :current-credit="modalCredit.currentCredit"
      @onConfirm="onConfirmModalCredit"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import AccountsFilter from '@/views/accounts/AccountsFilter'
import { VueGoodTable } from 'vue-good-table-custom'
import ModalIncrementDecrementCreditComponent from '@/components/ModalIncrementDecrementCreditComponent'
import VueGoodTableRemote from '@/components/VueGoodTableRemote'
import VueGoodTableSwitchCompactMode from '@/components/VueGoodTableSwitchCompactMode'
import {
  statusColorBoolean,
  statusActiveBoolean,
} from '@/utils/statusBoolean.util'
import Button from '@/components/Button.vue'

import { DatetimeFormat } from '@/utils/date-format'

export default {
  components: {
    Button,
    AccountsFilter,
    VueGoodTable,
    VueGoodTableRemote,
    VueGoodTableSwitchCompactMode,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    ModalIncrementDecrementCreditComponent,
  },
  data() {
    return {
      columns: [
        {
          label: this.$t('Username'),
          field: 'username',
          width: '130px',
        },
        {
          label: this.$t('Contact'),
          field: 'contact',
          sortable: false,
        },
        {
          label: this.$t('Level'),
          field: 'type',
        },
        {
          label: this.$t('Currency'),
          field: 'currency',
          sortable: false,
          width: '80px',
        },
        {
          label: this.$t('Status'),
          field: 'status',
          sortable: false,
        },
        {
          label: this.$t('Credit'),
          field: 'creditLimit',
          type: 'decimal',
        },
        {
          label: this.$t('MaxCreditLimit'),
          field: 'maxCreditLimit',
          type: 'decimal',
        },
        {
          label: '',
          field: 'ActionCredit',
          sortable: false,
        },
        {
          label: this.$t('Setting'),
          field: 'editSetting',
          sortable: false,
          width: '60px',
        },
        {
          label: this.$t('Last Time Login'),
          field: 'last_login',
          width: '200px',
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 100,
      },

      downlineId: null,

      modalCredit: {
        show: false,
        type: "INCREMENT",
        username: "",
        id: "",
        currentCredit: 0,
      },

      selectedRows: [],
    }
  },
  computed: {
    breadcrumbs() {
      return store.getters['appBreadCrumb/getBreadcrumbs']
    },
    userInfo() {
      return store.getters['auth/userInfo']
    },
    user() {
      return store.getters['auth/user']
    },
  },
  watch: {
    $route() {
      const { downlineId } = this.$route.params
      this.setDownlineParam(downlineId)
      this.onLoadData();

      if (!downlineId) {
        store.commit('appBreadCrumb/REMOVE_BREADCRUMBS_CUSTOM')
      }
    },
  },
  mounted() {
    const { downlineId } = this.$route.params
    this.setDownlineParam(downlineId)
    this.onLoadData()
  },
  methods: {
    statusColorBoolean,
    statusActiveBoolean,
    DatetimeFormat,

    setDownlineParam(downlineId) {
      this.downlineId = downlineId
    },

    onClickAddNewAccount() {
      this.$router.push({ name: "accounts-create" })
    },

    async onLoadData() {
      const params = this.$refs.tableDataMember.getParams()
      this.$wait.start('table-loading-data')
      try {
        const mainPath = '/agent/accounts'
        const path = this.downlineId ? `${mainPath}/${this.downlineId}` : mainPath
        const { data } = await this.$http.get(path, {
          params,
        })
        this.rows = data?.data?.docs ?? []
        this.totalRecords = data?.data?.totalDocs ?? 0

        // breadcrumb
        const { uplinesGroup } = data.data
        for (let index = 0; index < uplinesGroup.length; index += 1) {
          const uplineGroup = uplinesGroup[index]
          store.commit('appBreadCrumb/ADD_BREADCRUMBS', {
            text: uplineGroup.username,
            custom: true,
            to: {
              name: 'accounts',
              params: {
                downlineId: uplineGroup.id,
              },
            },
          })
        }
      } finally {
        this.$wait.end('table-loading-data')
      }
    },

    onIncrementCredit(row) {
      this.modalCredit.currentCredit = row.creditLimit
      this.modalCredit.username = row.username
      this.modalCredit.id = row._id
      this.modalCredit.type = "INCREMENT"
      this.modalCredit.show = true
    },
    onDecrementCredit(row) {
      this.modalCredit.currentCredit = row.creditLimit
      this.modalCredit.username = row.username
      this.modalCredit.id = row._id
      this.modalCredit.type = "DECREMENT"
      this.modalCredit.show = true
    },

    selectionChanged(selectedRows) {
      this.selectedRows = selectedRows
    },
    async onUpdateMaxCreditLimit() {
      const { selectedRows } = this
      if (!selectedRows || !Array.isArray(selectedRows) || Array.from(selectedRows).length === 0) {
        this.$swal({
          icon: 'error',
          title: this.$t('Please Select Rows'),
        })
        return
      }

      if (selectedRows.length > 100) {
        this.$swal({
          icon: 'error',
          title: this.$t('Max Update 100 Rows'),
        })
        return
      }

      const ids = selectedRows.map(list => list.id)

      this.$wait.start('loading-on-action-update-max-credit-limit')
      try {
        const { data } = await this.$http.patch(`/agent/accounts/max-credit-limit`, {
          ids,
        })
        if (!data.success) {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
          return
        }

        const agent = data?.data?.agent ?? null
        if (agent && agent?.username === this.user.username) {
          // update agent
          store.dispatch('auth/setUser', agent)

          // update agentInfo
          store.commit('auth/userInfo', { ...this.userInfo, creditLimit: agent.creditLimit })
        }

        const updatedTotal = data?.data?.updatedTotal ?? 0
        if (updatedTotal > 0) {
          await this.onLoadData()
        }

        this.$swal({
          icon: 'success',
          title: `Success Updated : ${updatedTotal} Accounts`,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: this.$t(error.message),
        })
      } finally {
        this.$wait.end('loading-on-action-update-max-credit-limit')
      }
    },

    onViewDownline(row) {
      if (row.type === 'AGENT') {
        return
      }
      const downlineId = row._id
      this.setDownlineParam(downlineId)
      this.$router.replace({
        name: 'accounts',
        params: {
          downlineId: row._id,
        },
      })
    },
    async  onConfirmModalCredit(total) {
      if (!total || Number.isNaN(total) || Number(total) <= 0) {
        this.$swal({
          icon: 'error',
          title: this.$t('INPUT_INVALID_CREDIT'),
        })
        return
      }

      this.$wait.start('loading-on-action-credit')
      try {
        const { data } = await this.$http.patch(`/agent/accounts/credit/${this.modalCredit.id}`, {
          total: parseFloat(total),
          type: this.modalCredit.type,
        })
        if (!data.success) {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
          return
        }

        const currentIndexByMember = this.rows.findIndex(list => String(list._id) === String(this.modalCredit.id))
        if (currentIndexByMember !== -1) {
          const allRows = [...this.rows]
          allRows[currentIndexByMember] = { ...allRows[currentIndexByMember], creditLimit: data.data.user.creditLimit }
          this.rows = allRows
        }
        this.$swal({
          icon: 'success',
          title: this.modalCredit.type === 'INCREMENT' ? this.$t('IncrementCreditSuccess') : this.$t('DecrementCreditSuccess'),
        })

        const agent = data?.data?.agent ?? null
        if (agent) {
          // update agent
          store.dispatch('auth/setUser', agent)

          // update agentInfo
          store.commit('auth/userInfo', { ...this.userInfo, creditLimit: agent.creditLimit })
        }

        this.modalCredit.show = false
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: this.$t(error.message),
        })
        this.modalCredit.show = false
      } finally {
        this.$wait.end('loading-on-action-credit')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
